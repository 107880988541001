import { User } from 'firebase/auth';
import { arrayUnion, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore';
import { getUIDMain } from '../src/service/user-service'
import axios from "axios"
import { ENABLE_ERROR_LOGGING } from '../src/settings/project-settings';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { Buffer } from "buffer";


export const isLoggedInPortal = ( authPortal : any ) => {
    const isAuthenticated = (authPortal.currentUser !== null &&  authPortal.currentUser !== undefined)
    return isAuthenticated
}

export const getCurrentUserPortal = async ( authPortal : any ) => {
    if(authPortal.currentUser) return await authPortal.currentUser as User
    return false
}

export const getUserTokenPortal = async ( authPortal : any ) => {
    const currentUser = await getCurrentUserPortal( authPortal )
    const hasCurrentUser : boolean =  currentUser !== false
    if(hasCurrentUser) return await (currentUser as User).getIdToken()
    return undefined
  }

export const getUserEmailPortal = async ( authPortal : any ) => {
    const currentUser = await getCurrentUserPortal( authPortal )
    return currentUser ? currentUser.email as string : ""
}

export const getUIDPortal = ( authPortal : any ) => {
    return authPortal.currentUser.uid
}

export const isLoggedInAndRegisteredPortal = ( authPortal : any  ) => {
    const isAuthenticated = (
        authPortal.currentUser !== null && 
        authPortal.currentUser !== undefined &&
        authPortal.currentUser.emailVerified === true
    )
    return isAuthenticated
}

export const incrementCounterPortal = async ( dbPortal : any, uid : string ) => {
    console.log('setting user verification...')
    const key : string = 'verified'
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const ref = doc(dbPortal, `counter/${uidWithReplacement}`)
    await updateDoc(ref,{ count : increment(1)}).catch(( error )=>{console.log('failed')})
}

// should change to: getNewGeolocationDataFB
export const getGeolocationDataFBPortal = async (  dbPortal : any, uid : string ) => {
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const ref = doc(dbPortal, `analytics/${uidWithReplacement}`)
    const response = await getDoc(ref).catch(( error )=>{console.log('failed 1' )})
    if( response ) return response.data(); else return false
}

// should change to: getNewContactsDataFB
export const getContactsDataFBPortal = async ( dbPortal : any, uid : string ) => {
    if(!uid) return console.log("failed 1")
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const ref = doc(dbPortal, `contacts/${uidWithReplacement}`)
    const response = await getDoc(ref).catch(( error )=>{console.log('failed 2' )})
    if( response ) return response.data(); else return false
}

export const clearContactDataFBPortal = async ( dbPortal : any, uid : string  ) => {
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const contactsPortalref = doc( dbPortal, `contacts/${uidWithReplacement}`)
    await setDoc(contactsPortalref, {}).then(()=>{
        console.log("successfully deleted...")
    }).catch(( error )=>{ console.log(error) })
}

export const getTempDataFBPortal = async ( dbPortal : any, uid ?: string ) => {
    console.log('uid: ' + uid )
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    if(!uidWithReplacement) return console.log("failed 1")
    const ref = doc(dbPortal, `temp/${uidWithReplacement}`)
    const response = await getDoc(ref).catch(( error )=>{console.log('failed 2' )})
    if( response ) return response.data(); else return false
}

export const clearTempDataFBPortal = async ( dbPortal : any, uid : string  ) => {
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const contactsPortalref = doc( dbPortal, `temp/${uidWithReplacement}`)
    await setDoc(contactsPortalref, {}).then(()=>{
        console.log("successfully deleted...")
    }).catch(( error )=>{ console.log(error) })
}

export const saveTempDataFBPortal = async ( dbPortal : any, uid : string, data : any  ) => {
    if(!data) return console.log("failed 1")
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    console.log(`saving to: ${uidWithReplacement} -> ${JSON.stringify(data)}`)
    const ref = doc( dbPortal, `temp/${uidWithReplacement}`)
    return await setDoc(ref,{ ...data },{ merge : true })
    .then(( response : any )=>{
        if(ENABLE_ERROR_LOGGING ) console.log('success' ) 
        if(ENABLE_ERROR_LOGGING ) return 'success'
    })
    .catch(( error )=>{ 
        if( ENABLE_ERROR_LOGGING ) console.log('failed 2 - ' + error ) 
        if( ENABLE_ERROR_LOGGING ) return error.code 
    })
}

export const submitGeolocationDataFBPortal = async ( dbPortal : any, uid : string ) => {
    const geolocationData : any = await getSubmitGeolocationData()
    if(!geolocationData) return console.log("failed 1")
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const ref = doc(dbPortal, `analytics/${uidWithReplacement}`)
    const entryId : string = String(new Date().getTime())
    await setDoc(ref,{  [entryId] : geolocationData },{ merge : true }).catch(( error )=>{console.log('failed 2 - ' + error )})
}

export const submitContactDataFBPortal = async ( dbPortal : any, uid : string, contactData ?: any  ) => {
    if(!contactData) return console.log("failed 1")
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const ref = doc( dbPortal, `contacts/${uidWithReplacement}`)
    const entryId : string = String(new Date().getTime())
    return await setDoc(ref,{  [entryId] : contactData },{ merge : true })
    .then(( response : any )=>{
        if(ENABLE_ERROR_LOGGING ) console.log('success' ) 
        if(ENABLE_ERROR_LOGGING ) return response
    })
    .catch(( error )=>{ 
        if( ENABLE_ERROR_LOGGING ) console.log('failed 2 - ' + error ) 
        if( ENABLE_ERROR_LOGGING ) return error.code 
    })
}

export const uploadStringToStorage = async ( props : {
    firestorePortal : any, 
    folder : string, 
    pageId : string, 
    data : any,
    onSuccess ?: ( url ?: string ) => void  
    onFail ?: ( error ?: any ) => void  
    
}) => {
    const buffer = Buffer.from(JSON.stringify(props.data), 'utf-8').toString('base64')
    const message4 = `data:text/plain;base64,${buffer}`;
    const filepath = `${props.folder}/${props.pageId}`
    const storageRef = ref( props.firestorePortal, filepath )
    uploadString(storageRef, message4, 'data_url').then(async ( snapshot : any ) => {
        console.log('Uploaded a data_url string!');
        const url = await getDownloadURL( storageRef ).then( url => url );
        console.log( url )
        if(props.onSuccess) props.onSuccess( url )
    }).catch(( error : any )=>{
        if(props.onFail)props.onFail(error)
    })
}

export const addVisitTimeFBPortal = async ( dbPortal : any, uid : string ) => {
    console.log('setting user verification...')
    const key : string = 'verified'
    const uidWithReplacement = uid !== "card0" ? uid : getUIDMain()
    const ref = doc(dbPortal, `counter/${uidWithReplacement}`)
    //await   Doc(ref,{ visit : [  ]}, { merge : true }).catch(( error )=>{console.log('failed')})
    await updateDoc(ref,{ visit : arrayUnion(new Date().getTime())}).catch(( error )=>{console.log('failed')})
}



export const getSubmitGeolocationData = async () =>  {
    const VERSION : number = 1
    return await axios.get('https://ipapi.co/json/').then((response) => {
        let loc = response.data;
        let buffer : any = {
            country: loc.country_code,
            latitude: loc.latitude,
            longitude: loc.longitude,
            state : loc.region_code,
            zipcode : loc.postal,
            city : loc.city
        }
        return buffer
    }).catch((error) => {
        console.log(error);
        return false;
    });  
}


export const setPageFSPortal = async ( props : { 
    dbPortal : any, 
    pageId : string, 
    value : any
    onSuccess ?: ( response : any) => void 
  }) => {
        console.log(props.pageId )
        
        const contactsRef = doc(props.dbPortal, `pages/${props.pageId}`)
        return await setDoc(contactsRef, props.value,{ merge : true }).then( (
            response : any 
        ) => {
            if(props.onSuccess) props.onSuccess( response )
            if( ENABLE_ERROR_LOGGING ) console.log( 'successful response: ...' ) 
            if( ENABLE_ERROR_LOGGING ) console.log( JSON.stringify( response )) 
            return true
        }).catch( error => {
            if( ENABLE_ERROR_LOGGING ) console.log( error.code + " " + props.pageId ) 
            if( ENABLE_ERROR_LOGGING ) 
            return false
        })
  }


export const getOwners = async ( dbPortal : any, uid : string ) => {
    
    const ref = doc(dbPortal, `owners/${uid}`)
    const response = await getDoc(ref).catch(( error )=>{console.log('failed 2: ' + error.code  )})
    if( response ) return response.data(); else return false
}
  
  
export const addOwner = async ( props : {
    dbPortal : any, 
    uid : string, 
    value : any,
    onSuccess ?: ( response ?: any ) => void 
}) => {
    
    const ref = doc(props.dbPortal, `owners/${props.uid}`)
    return await setDoc(ref, props.value,{ merge : true }).then( (
        response : any 
    ) => {
        if(props.onSuccess) props.onSuccess( response )
        if( ENABLE_ERROR_LOGGING ) console.log( 'successful response: ...' ) 
        if( ENABLE_ERROR_LOGGING ) console.log( JSON.stringify( response )) 
        return true
    }).catch( error => {
        if( ENABLE_ERROR_LOGGING ) console.log( error.code ) 
        if( ENABLE_ERROR_LOGGING ) 
        return false
    })
    
}







// //arrayUnion
