import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, updateDoc, doc, increment, arrayUnion } from '@firebase/firestore'
import { getStorage } from "firebase/storage";
import { addOwner, addVisitTimeFBPortal, clearContactDataFBPortal, clearTempDataFBPortal, getContactsDataFBPortal, getCurrentUserPortal, getGeolocationDataFBPortal, getOwners, getTempDataFBPortal, getUIDPortal, getUserEmailPortal, getUserTokenPortal, incrementCounterPortal, isLoggedInAndRegisteredPortal, isLoggedInPortal, saveTempDataFBPortal, setPageFSPortal, submitContactDataFBPortal, submitGeolocationDataFBPortal, uploadStringToStorage } from "./firebase-portal-functions";
import { setDoc } from "firebase/firestore";
import { ENABLE_ERROR_LOGGING } from "../src/settings/project-settings";

const USE_FIREBASE_PORTAL_NAME = 'portal-storage-00'

const firebaseConfig = {
  apiKey: "AIzaSyD2lQfZeqjmZW5noQsoULon0wzKKzMNZsU",
  authDomain: "slap-kard-portal-storage-00.firebaseapp.com",
  projectId: "slap-kard-portal-storage-00",
  storageBucket: "slap-kard-portal-storage-00.appspot.com",
  messagingSenderId: "450652320463",
  appId: "1:450652320463:web:b3b296486bba9f72673dba"
};

export const firestoreAppPortalStorage00  = initializeApp(firebaseConfig,  USE_FIREBASE_PORTAL_NAME );
export const portalAuthStorage00          = getAuth(firestoreAppPortalStorage00);
export const portalStoragePortalStorage00 = getStorage(firestoreAppPortalStorage00)
export const dbPortalStorage00            = initializeFirestore(firestoreAppPortalStorage00, {
    //experimentalForceLongPolling: false,
    cacheSizeBytes: 1048576
});

const USE_FIREBASE_AUTH_PORTAL  = portalAuthStorage00
const USE_FIREBASE_DB_PORTAL    = dbPortalStorage00

export const isLoggedInPortal_STORAGE_00              = () => isLoggedInPortal( USE_FIREBASE_AUTH_PORTAL )
export const getCurrentUserPortal_STORAGE_00          = () => getCurrentUserPortal( USE_FIREBASE_AUTH_PORTAL )
export const getUserEmailPortal_STORAGE_00            = () => getUserEmailPortal( USE_FIREBASE_AUTH_PORTAL )
export const getUIDPortal_STORAGE_00                  = () => getUIDPortal( USE_FIREBASE_AUTH_PORTAL )
export const isLoggedInAndRegisteredPortal_STORAGE_00 = () => isLoggedInAndRegisteredPortal( USE_FIREBASE_AUTH_PORTAL )
export const incrementCounterPortal_STORAGE_00        = async ( uid : string ) => await incrementCounterPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getGeolocationDataFBPortal_STORAGE_00    = async ( uid : string ) => await getGeolocationDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getContactsDataFBPortal_STORAGE_00       = async ( uid : string ) => await getContactsDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const clearContactDataFBPortal_STORAGE_00      = async ( uid : string ) => await clearContactDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getTempDataFBPortal_STORAGE_00           = async ( uid : string ) => await getTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const clearTempDataFBPortal_STORAGE_00         = async ( uid : string ) => await clearTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const submitGeolocationDataFBPortal_STORAGE_00 = async ( uid : string ) => await submitGeolocationDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const addVisitTimeFBPortal_STORAGE_00          = async ( uid : string ) => await addVisitTimeFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const saveTempDataFBPortal_STORAGE_00          = async ( uid : string, data : any  ) => await saveTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid, data ) 
export const submitContactDataFBPortal_STORAGE_00     = async ( uid : string, contactData : any  ) => await submitContactDataFBPortal( USE_FIREBASE_DB_PORTAL, uid, contactData )
export const uploadStringToStorage_STORAGE_00         = async ( props : { folder : string, pageId : string, data : any, onSuccess ?: ( url ?: string ) => void, onFail ?: ( error ?: any ) => void  }) => uploadStringToStorage({ ...props, firestorePortal : portalStoragePortalStorage00 })

export const STORAGE_ROOT : string = 'https://firebasestorage.googleapis.com/v0/b/slap-kard-portal-storage-00.appspot.com/o'
//export const SOCIAL_SHARE_STORAGE_ROOT : string = 'https://firebasestorage.googleapis.com/v0/b/slap-kard.appspot.com/o'
export const SOCIAL_SHARE_STORAGE_ROOT : string = STORAGE_ROOT


export const getUserTokenPortal_STORAGE_0 = async () => await getUserTokenPortal( USE_FIREBASE_AUTH_PORTAL )

export const setPageFSPortal_STORAGE_00 = async ( props : { 
  pageId : string, 
  value : any
  onSuccess ?: ( response : any) => void 
}) => await setPageFSPortal({ ...props, dbPortal :  dbPortalStorage00 })


export const getOwnersFSPortal_STORAGE_00 = async ( uid : string ) => await getOwners( dbPortalStorage00,  uid )

export const addOwnerFSPortal_STORAGE_00 = async ( props: { uid : string, value : any }  ) => await addOwner({ dbPortal : dbPortalStorage00, uid : props.uid, value : props.value })


